.about__banner{
    background-size: cover;

    width: 80%;
    height: 40vh;

    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@keyframes banner-animation {
    from{
        transform: translateY(5%); 
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1; 
    }
}

@media (width <= 758px) {
    .about__banner{
        width: 100%;
    }
}

.about__banner .banner__text-box{   
    background-color: rgb(255, 214, 153, .9);
    width: 100%;
    height: 100%;
    padding: 10px;
    font-family: 'Playfair', serif;
    text-align: center;
}

.about__banner .banner__text-box h1 {
    font-size: 2rem;
    font-weight: 300;
}

.about__banner .banner__text-box o {
    font-size: 1.5rem;
    font-weight: 100;
}

.value__container{
    list-style: none;
    display: flex;
    
    flex-direction: column;
    margin-bottom: 30px;
}

.value__title{
    margin: 0px 10% 20px 10%;
    background-color: #66CCFF;
    color: white;
    border-radius: 10px;
    padding: 10px;
}


.values_list__container{
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.values_list__container li {
    display: flex;
    justify-content: center;
}

.about__values{
    background: url('./../../assets/fondos/fondo11.jpg') no-repeat center;     
    background-size: cover;
}
.about__info{
   display: flex;
   flex-direction: row;
   align-items: center;
}
.about__info img{
    width: 600px;
    margin-right: 30px;
}
@media (width <= 1200px) {
    .about__info img{
        display: none;
    }   
}