.footer {
    background-color: #FF66B2;
    padding: 50px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.footer-item {
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
}
