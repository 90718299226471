.list-item{
    margin-top: 30px;
    margin-left: 10%;
    margin: 0px 10% 20px 10%;
}

.list-item p {
    margin-bottom: 0px;
}

.list__index{
    background-color: #66CCFF;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.list__index > p {
    color: white;
    font-size: 0.8rem;
    display: flex;  
    align-self: center;
    margin: 0px;
}

.list__text{
    font-size: 0.8rem;
    min-width: 200px;
}

 @media (width >= 770px) {
    .list-item{
        display: flex;
        align-self: center;
    }   
 }