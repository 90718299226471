.products__main-container{
    background-color: rgb(255, 250, 250);
}
.products__banner{
    background-size: cover;

    width: 80%;
    height: 40vh;

    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (width <= 758px) {
    .products__banner{
        width: 100%;
    }
}

@keyframes banner-animation {
    from{
        transform: translateY(20%); 
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1; 
    }
}

.products__banner .banner__text-box{   
    background-color: rgb(255, 214, 153, .9);
    width: 100%;
    height: 100%;
    padding: 10px;
    font-family: 'Playfair', serif;
    text-align: center;
}

.products__banner .banner__text-box h1 {
    font-size: 2rem;
    font-weight: 300;
}

.products__banner .banner__text-box o {
    font-size: 1.5rem;
    font-weight: 100;
}

.products__category-slect{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.products__input{
    height: 60px;
    display: flex;
    align-items: center;
    background-color: white;
    border: solid 1px #666666;
    border-radius: 10px;
    padding: 0px 10px 0px 10px;
    margin: 0px 10% 0px 10%; 
}
.products__label{
    margin: 0px 10% 10px 10%; 
}
.p-dropdown-items-wrapper{
    border: 2px solid transparent; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.p-dropdown-items {
    display: flex;
    flex-direction: column;   
    padding: 0px;
}

.p-dropdown-item{
    margin: 0px 5px 5px 10px;
    padding: 5px;
}

.p-dropdown-item:hover {
    background-color: #F5F5F5;
}

.products__cards-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}