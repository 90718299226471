html{
  font-size: 1.1rem;
}

body {
  margin: 0px;
  padding: 0px;
  min-width: 400px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.clickable:hover {
  cursor: pointer;
}