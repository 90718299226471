section {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.banner{
    background: url('./../../assets/home/banner2.jpg') no-repeat center fixed;     
    background-size: cover;

    width: 100%;
    height: 85vh;

    display: flex;
    gap: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.banner__title{
    text-align: center;
    background-color: white;
    border-radius: 10px;
    padding: 1px;
}
@keyframes banner-animation-home {
    from{
        opacity: 0;
    }
    to {
        opacity: 1; 
    }
}
@keyframes logo-animation {
    from {
        scale: 1;
    }
    to {
        scale: 1.03;
    }
}
.banner__text-box:hover{    
    cursor: pointer;
}
.banner__logo{
    height: 240px;
}
.banner .banner__text-box{   
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    width: 300px;
    height: 300px;

    border-radius: 50%;
    padding: 10px;
}
.banner .banner__text-box h1 {
    font-family: 'Playfair', serif;
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
}
.fak__background {
    background: url('./../../assets/fondos/fondo11.jpg') no-repeat center;     
    background-size: cover;
}
.home_steps{
    background: url('./../../assets/fondos/fondo12.avif') no-repeat center;     
    background-size: cover;
}