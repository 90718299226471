.gallery{
    background-color: #ECECEC;
    padding: 30px;
}
.gallery__image{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.gallery__arrow{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    height: 50px;
    width: 50px;
    border: 0px;
}
.gallery__image:hover{
    cursor: pointer; 
    animation: card-animation 1s;
}
@keyframes card-animation {
    from {
        scale: 0.95;
    }
    to {
        scale: 1;
    }
}